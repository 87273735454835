.nav-tabs {
  border-bottom: 0;
  margin-bottom: 3px;
  position: relative;
  z-index: 1;
}
.nav-tabs :hover {
  cursor: pointer;
}
.nav-tabs>li {
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  margin-bottom: 0;
}
.nav-tabs>li>button {
  display: block;
  width: 100%;
  line-height: inherit;
  margin: 0;
  height: inherit;
  padding: 0 10px 0 10px;
  outline: none;
}
.nav-tabs>li>button:after {
  background-color: transparent;
  bottom: -3px;
  content: ' ';
  display: block;
  height: 3px;
  width: 100%;
}
.nav-tabs>li>button,
.nav-tabs>li.active>button,
.nav-tabs>li>button:hover,
.nav-tabs>li.active>button:hover,
.nav-tabs>li>button:active,
.nav-tabs>li.active>button:active,
.nav-tabs>li>button:focus,
.nav-tabs>li.active>button:focus {
  color: var(--active-color);
  background-color: transparent;
  border: 0;
}
.nav-tabs>li.active>button {
  font-weight: bold;
}
.nav-tabs>li.active>button:after {
  background-color: var(--active-color);
}
.nav {
  padding-left: 0;
  list-style: none;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li {
  float: left;
  margin-bottom: -1px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.modal-open {
  overflow: hidden;
}
.modal {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: rgba(0,0,0,0.5);
}
.modal .modal-dialog .modal-content {
  background-color: var(--ui-gray-darker);
  border-color: var(--ui-border-color);
  color: var(--text-secondary-color);
  border: 0;
}
.modal .modal-dialog .modal-content .modal-header,
.modal .modal-dialog .modal-content .modal-footer {
  border-color: var(--ui-border-color);
}
.modal .modal-dialog .modal-content .dialog-separator,
.modal .modal-dialog .modal-content .dialog-separator-before,
.modal .modal-dialog .modal-content .dialog-separator-after {
  position: relative;
  height: 40px;
}
.modal .modal-dialog .modal-content .dialog-separator:before,
.modal .modal-dialog .modal-content .dialog-separator:after,
.modal .modal-dialog .modal-content .dialog-separator-before:before,
.modal .modal-dialog .modal-content .dialog-separator-after:after {
  background-color: #000;
  content: ' ';
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%;
}
.modal .modal-dialog .modal-content .dialog-separator:before,
.modal .modal-dialog .modal-content .dialog-separator-before:before {
  top: -3px;
}
.modal .modal-dialog .modal-content .dialog-separator:after,
.modal .modal-dialog .modal-content .dialog-separator-after:after {
  bottom: -3px;
}
.modal .modal-dialog .modal-content .modal-body {
  color: var(--text-primary-color);
  padding: 16px 22px 0px;
  position: relative;
}
.modal .modal-dialog .modal-content .modal-body ::-webkit-scrollbar {
  width: 6px;
  background-color: var(--ui-gray-dark);
}
.modal .modal-dialog .modal-content .modal-body ::-webkit-scrollbar-thumb {
  background-color: var(--ui-gray-light);
  border-radius: 5px;
}
.modal .modal-dialog .modal-content .modal-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: #000;
  padding: 19px 22px 17px;
  position: relative;
}
.modal .modal-dialog .modal-content .modal-header h4 {
  color: var(--text-secondary-color);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-right: 24px;
}
.modal .modal-dialog .modal-content .modal-footer {
  border-top: 0;
  padding-top: 0;
}
.modal .modal-dialog .card-round {
  background-color: var(--ui-gray-dark);
  padding: 10px;
}
.modal .modal-dialog .modal-header {
  position: relative;
}
.modal.fade .modal-dialog {
  -webkit-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
.modal.in .modal-dialog {
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  background-clip: padding-box;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: #fff;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.in {
  opacity: 0;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn+.btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn+.btn {
  margin-left: -1px;
}
.modal-footer .btn-block+.btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,0.5);
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.button-close,
.modal-dialog button.close {
  color: var(--text-secondary-color);
  height: 25px;
  opacity: 1;
  overflow: hidden;
  text-align: center;
  text-shadow: none;
  width: 25px;
  font-size: 25px;
  font-weight: lighter;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
.ModalHeader ol,
.ModalHeader ul {
  margin-top: 0;
}
