.tableListItem {
  display: flex;
  margin-left: -6px;
  margin-top: 2px;
  padding-left: 6px;
  opacity: 0.7;
  cursor: pointer;
  width: calc(100% + 6px);
}
.tableListItem:hover {
  opacity: 1;
}
.tableListItem.selected {
  opacity: 1;
}
.tableListItem.selected .itemIndex {
  color: $activeColor;
}
.tableListItem.hasWarning .warning {
  display: block;
}
.tableListItem .itemIndex {
  background-color: var(--ui-gray);
  color: var(--text-secondary-color);
  cursor: pointer;
  flex: 1;
  max-width: 25px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
  padding: 10px;
  text-align: center;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
}
.tableListItem .itemContent {
  flex: 1;
  color: var(--text-secondary-color);
  width: calc(100% - 30px);
  font-weight: 400;
  margin-left: 9px;
  margin-top: 9px;
}
.tableListItem .warning-icon {
  display: none;
}
